.organization-page .files__header {
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.organization-page .ant-breadcrumb .ant-breadcrumb-link {
  transition: 0.3s all;
  cursor: pointer;
}
.organization-page .ant-breadcrumb .ant-breadcrumb-link:hover {
  opacity: 0.5;
}
.organization-page .rules__block {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s all;
}
.organization-page .rules__block:hover {
  opacity: 0.5;
}
.organization-page .rules__block p {
  margin: 0 10px 0 0;
  font-weight: bold;
  text-transform: uppercase;
}
.organization-page .rules__block svg {
  width: 18px;
  height: 18px;
}
.organization-page .search__block {
  display: block;
  text-align: right;
  margin-bottom: 10px;
}
.organization-page .actions__block {
  display: flex;
}
.organization-page .actions__block:first-child {
  margin-right: 10px;
}
.organization-page .submit-button {
  margin-top: 20px;
}
.organization-page .form__block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.organization-page .upload__button {
  margin-bottom: 20px;
}
.organization-page .category__header {
  margin-top: 10px;
}
.organization-page .upload__name {
  padding-left: 10px;
}
.organization-page .taxonomy__description {
  margin-top: 20px;
  margin-bottom: 20px;
}
.organization-page .modal__description {
  margin-top: 20px;
  margin-bottom: 20px;
}
.organization-page .import__file {
  display: flex;
  align-items: center;
}
.organization-page .import__file .custom__input {
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 4px 15px;
  border: 1px solid #313131;
  width: 300px;
}
.organization-page .import__file .custom__input span {
  margin-right: 5px;
}
.organization-page .import__file input {
  position: absolute;
  opacity: 0;
}
.organization-page .import__file .upload__name {
  margin: 0 10px 0 0;
}
.organization-page .download__all {
  display: flex;
  align-items: center;
  margin-left: 15px;
}