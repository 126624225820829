.segmentation-filter-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .segmentation-filter-header {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .segmentationfilters-filter-title {
      max-width: 70%;
    }

    .segmentationfilters-filter-type {
      color: #AAA;
      font-size: .9em;
      font-style: italic;
    }

    .segmentationfilters-filter-subheader {
      display: flex;
      gap: 1em;

      .segmentationfilters-filter-clear {
        color: #FF2277;
        cursor: pointer;
        font-size: .9em;
        font-style: italic;
      }

      .segmentationfilters-filter-clear:hover {
        color: #FF0033;
      }
    }
  }

  .segmentation-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: .5em;
    width: 100%;

    input::placeholder {
      font-size: 1em !important;
    }

    .ant-input-number {
      width: 100%;
    }
  
    .ant-select {
      width: 100%;
    }
  }
}