.group-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.group-editor .group-editor-columns {
  display: flex;
  justify-content: space-between;
}
.group-editor .group-editor-columns .group-editor-column {
  border-right: 1px solid #EFEFEF;
  padding: 0 15px;
  width: 25%;
}
.group-editor .group-editor-columns .group-editor-column:last-child {
  border-right: none;
}
.group-editor .group-editor-columns .group-editor-column .ant-input::placeholder {
  font-size: 16px;
  padding-left: 3px;
}
.group-editor .group-editor-columns .group-editor-column .ant-select {
  color: #6c757d;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-action {
  background: none;
  border: 1px solid #21416F;
  border-radius: 25px;
  color: #21416F;
  margin-top: 15px;
  max-width: 300px;
  padding: 10px 0;
  text-align: center;
  transition: 0.3s all;
  width: 100%;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-action:hover {
  background: #144176;
  color: #fff;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-action-clear {
  color: #C82922;
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: 18px;
  margin-top: 14px;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-action-delete {
  cursor: pointer;
  height: 14px;
  margin-right: 10px;
  position: relative;
  transition: 0.4s all;
  width: 14px;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-action-delete:after {
  background: #C82922;
  border: 1px solid #C82922;
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  transform: rotate(45deg);
  width: 100%;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-action-delete:before {
  background: #C82922;
  border: 1px solid #C82922;
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 100%;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-action-delete:hover {
  opacity: 0.4;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-active-list {
  margin: 15px 0 0;
  max-height: 560px;
  overflow-y: auto;
  padding: 0;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-active-list li {
  align-items: center;
  display: flex;
  font-size: 16px;
  list-style: none;
  margin-bottom: 10px;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-column-subtitle {
  color: #6c757d;
  font-style: italic;
  margin: 0;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-column-title {
  color: #134176;
  font-size: 18px;
  margin: 0;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-column-title span {
  font-weight: bold;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-column-content {
  margin-top: 40px;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-column-content .ant-input-affix-wrapper, .group-editor .group-editor-columns .group-editor-column .group-editor-column-content .ant-input-wrapper input {
  border: none;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-column-content .ant-input-affix-wrapper:focus, .group-editor .group-editor-columns .group-editor-column .group-editor-column-content .ant-input-affix-wrapper:hover, .group-editor .group-editor-columns .group-editor-column .group-editor-column-content .ant-input-affix-wrapper:active, .group-editor .group-editor-columns .group-editor-column .group-editor-column-content .ant-input-wrapper input:focus, .group-editor .group-editor-columns .group-editor-column .group-editor-column-content .ant-input-wrapper input:hover, .group-editor .group-editor-columns .group-editor-column .group-editor-column-content .ant-input-wrapper input:active {
  border: none;
  box-shadow: none;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-column-content .ant-input-search {
  margin-top: 40px;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-column-content .ant-input-search-button {
  align-items: center;
  border: none;
  display: flex;
  justify-content: center;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-column-content .group-editor-description {
  color: #134176;
  margin-bottom: 25px;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-column-content .group-editor-name-input {
  color: #AAA;
  padding: 10px 0 10px 5px;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-column-content .group-editor-name-input::placeholder {
  color: #AAA;
  font-size: 16px;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-input {
  border: 1px solid #D9D9D9;
  border-radius: 2px;
  width: 100%;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-input input {
  color: #AAA;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-input input::placeholder {
  color: #AAA;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-input svg {
  color: #21416F;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-selection-list {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  max-height: 480px;
  min-height: 400px;
  overflow-y: auto;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-selection-list .ant-checkbox + span {
  font-size: 16px;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-selection-list .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #134176;
  border-color: #134176;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-selection-list .ant-checkbox-wrapper {
  margin: 0 0 10px;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-switch {
  align-items: center;
  display: flex;
  margin-top: 14px;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-switch .ant-switch {
  margin-right: 10px;
}
.group-editor .group-editor-columns .group-editor-column .group-editor-switch .ant-switch-checked {
  background: #21416F;
}
.group-editor .group-editor-columns .group-editor-comparison {
  background: #FEFEDC;
  border-left: 1px solid #EFEFEF;
  overflow: hidden;
}
.group-editor .group-editor-modal-actions-wrapper {
  bottom: 75px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 10px;
}
.group-editor .group-editor-modal-actions-wrapper .group-editor-modal-actions {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}
.group-editor .group-editor-modal-actions-wrapper .group-editor-modal-actions button {
  background: #134176;
  border: 1px solid #21416F;
  border-radius: 25px;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 22px;
  transition: 0.3s all;
}
.group-editor .group-editor-modal-actions-wrapper .group-editor-modal-actions button:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.group-editor .group-editor-modal-actions-wrapper .group-editor-modal-actions button:hover {
  opacity: 0.4;
}
.group-editor .group-editor-modal-actions-wrapper .group-editor-modal-actions .button__cancel {
  background: transparent;
  border: none;
  color: #C82922;
  font-size: 14px;
  line-height: 18px;
}
.group-editor .group-editor-modal-actions-wrapper .group-editor-modal-actions .button__cancel:hover {
  color: #8b0a02;
  opacity: 1;
}