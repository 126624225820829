.organization-page {

  .files__header {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .ant-breadcrumb {

    .ant-breadcrumb-link {
      transition: .3s all;
      cursor: pointer;

      &:hover {
        opacity: .5;
      }
    }
  }

  .rules__block {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    transition: .3s all;

    &:hover {
      opacity: .5;
    }

    p {
      margin: 0 10px 0 0;
      font-weight: bold;
      text-transform: uppercase;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .search__block {
    display: block;
    text-align: right;
    margin-bottom: 10px;
  }

  .actions__block {
    display: flex;

    &:first-child {
      margin-right: 10px;
    }
  }

  .submit-button {
    margin-top: 20px;
  }

  .form__block {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .upload__button {
    margin-bottom: 20px;
  }

  .category__header {
    margin-top: 10px;
  }

  .upload__name {
    padding-left: 10px;
  }

  .taxonomy__description {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .modal__description {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .import__file {
    display: flex;
    align-items: center;

    .custom__input {
      display: flex;
      align-items: center;
      margin-right: 10px;
      padding: 4px 15px;
      border: 1px solid #313131;
      width: 300px;

      span {
        margin-right: 5px;
      }
    }

    input {
      position: absolute;
      opacity: 0;
    }

    .upload__name {
      margin: 0 10px 0 0;
    }

  }

  .download__all {
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
}