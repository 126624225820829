.report-page-wrapper {
  height: 100%; 
  padding: 15px 0;

  .report-page-header {
    align-items: center; 
    display: flex; 
    justify-content: space-between;
    width: 100%;

    .report-page-header-group {
      align-items: center;
      display: flex;
      gap: 20px;

      .report-page-header-item {
        .report-header-popover {
          align-items: center;
          border: 2px solid #A0A7B7;
          border-radius: 4px;
          color: #134176;
          cursor: pointer;
          display: flex;
          font-size: 14px;
          line-height: 23px;
          padding: 11px 12px;

          p {
            margin: 0;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          svg {
            color: #21416F;
            margin-left: 50px;
          }
        }
      }
    }

    .report-page-export {
      margin-right: auto;
    }

    .report-page-period-select-wrapper {
      display: flex;
      flex-direction: column;
      min-width: 200px;

      .report-page-period-select {
        border: 2px solid rgb(160, 167, 183);
        border-radius: 4px;
      }
    }

    .report-page-group-selector {
      align-items: center;
      display: flex;
      justify-content: flex-end;
      text-align: right;

      label {
        margin: 0 10px 0 0;
      }
    }
  }
}

.field__item > div {
  height: 100%;
  padding-bottom: 10px;
}

.field__item > div > div {
  height: 100%;
}

.field__item > div > div > table {
  height: 100%;
}

tr, td {
  padding-top: 10px;
  padding-bottom: 10px;
}

iframe {
  margin-top: -10px;
}

article.node--type-basic-page-report-blocks {
  & > header {
    display: none;
  }

  & > h2:is(:first-child) {
    display: none;
  }

  & > footer.node__meta {
    display: none;
  }
}

.report-header-popover-content {
  .wrapper {
    display: flex;

    .column {
      min-width: 300px;

      &:last-child {
        border-left: 1px solid #D8D8D8;
        padding-left: 50px;
      }

      .button-actions {
        display: flex;
        flex-direction: column;

        .action {
          background: transparent;
          border: 1px solid #21416F;
          border-radius: 24px;
          color: #21416F;
          display: flex;
          font-size: 14px;
          line-height: 18px;
          margin: 2em auto 0;
          outline: none;
          padding: 10px 30px;
          transition: .3s all;
        
          &:hover {
            background: #21416F;
            color: #fff;
          }
        }
  
        .apply {
          background: #21416F;
          color: #fff;
        
          &:disabled {
            background: #A0A7B7;
            cursor: not-allowed;
          }
  
          &:hover {
            opacity: .5;
          }
        }
      }

      .checkboxes {
        max-height: 300px;
        overflow-y: auto;
        padding-right: 1em;
        width: 100%;

        .ant-checkbox-group {
          display: flex;
          flex-direction: column;
        }

        .checkbox {
          align-items: center;
          border-bottom: 1px solid #D8D8D8;
          display: flex;
          justify-content: space-between;
          margin-bottom: 18px;
          padding-bottom: 18px;

          &:last-child {
            border-bottom: none;
          }

          label {
            display: flex;
            padding: 0;
          }

          .actions {
            align-items: center;
            display: flex;
    
            .delete {
              height: 14px;
              margin-left: 10px;
              position: relative;
              transition: .4s all;
              width: 14px;
              
              &:after {
                background: #C82922;
                border: 1px solid #C82922;
                content: '';
                display: block;
                position: absolute;
                top: 6px;
                transform: rotate(45deg);
                width: 100%;
              }
    
              &:before {
                background: #C82922;
                border: 1px solid #C82922;
                content: '';
                display: block;
                position: absolute;
                top: 6px;
                transform: rotate(-45deg);
                width: 100%;
              }
    
              &:hover {
                opacity: .4;
              }
            }
          }
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        max-height: 400px;
        min-height: 200px;
        overflow-y: auto;
        width: 100%;

        .list-item {
          align-items: center;
          border-bottom: 1px solid #DDD;
          display: flex;
          justify-content: space-between;
          padding: .25rem 0 .25rem .5rem;
          width: 100%;

          &:hover {
            background-color: #DDD;
            cursor: pointer;
          }

          &:last-child {
            border-bottom: none;
          }

          .list-item-title {
            margin: 0;
          }
        }
      }

      .subtitle {
        color: #000;
        font-size: 12px;
        font-style: italic;
        line-height: 18px;
        margin-bottom: 14px;
      }

      .title {
        color: #000;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 20px;
      } 
    }

    .single {
      border-left: none !important;
      padding: 0 !important;
    }
  }
}