.benchmark-block {
  display: flex;
  justify-content: center;
  min-height: 50px;
  padding-top: 32px;
}
.benchmark-block .column {
  padding: 0 10px;
  width: 25%;
}
.benchmark-block .category {
  border-left: 1px solid #EFEFEF;
  width: 25%;
}
.benchmark-block .question-group {
  border-left: 1px solid #EFEFEF;
  padding: 0 10px;
  width: 55%;
}
.benchmark-block .block-title {
  color: #7A8295;
  font-size: 14px;
  font-style: italic;
  line-height: 23px;
  margin: 0;
  padding: 0 10px;
}
.benchmark-block .block-subtitle {
  color: #000;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 40px;
  max-width: 300px;
  padding: 0 10px;
}