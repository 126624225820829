.report-page-wrapper {
  height: 100%;
  padding: 15px 0;
}
.report-page-wrapper .report-page-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.report-page-wrapper .report-page-header .report-page-header-group {
  align-items: center;
  display: flex;
  gap: 20px;
}
.report-page-wrapper .report-page-header .report-page-header-group .report-page-header-item .report-header-popover {
  align-items: center;
  border: 2px solid #A0A7B7;
  border-radius: 4px;
  color: #134176;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  line-height: 23px;
  padding: 11px 12px;
}
.report-page-wrapper .report-page-header .report-page-header-group .report-page-header-item .report-header-popover p {
  margin: 0;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.report-page-wrapper .report-page-header .report-page-header-group .report-page-header-item .report-header-popover svg {
  color: #21416F;
  margin-left: 50px;
}
.report-page-wrapper .report-page-header .report-page-export {
  margin-right: auto;
}
.report-page-wrapper .report-page-header .report-page-period-select-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 200px;
}
.report-page-wrapper .report-page-header .report-page-period-select-wrapper .report-page-period-select {
  border: 2px solid rgb(160, 167, 183);
  border-radius: 4px;
}
.report-page-wrapper .report-page-header .report-page-group-selector {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.report-page-wrapper .report-page-header .report-page-group-selector label {
  margin: 0 10px 0 0;
}

.field__item > div {
  height: 100%;
  padding-bottom: 10px;
}

.field__item > div > div {
  height: 100%;
}

.field__item > div > div > table {
  height: 100%;
}

tr, td {
  padding-top: 10px;
  padding-bottom: 10px;
}

iframe {
  margin-top: -10px;
}

article.node--type-basic-page-report-blocks > header {
  display: none;
}
article.node--type-basic-page-report-blocks > h2:is(:first-child) {
  display: none;
}
article.node--type-basic-page-report-blocks > footer.node__meta {
  display: none;
}

.report-header-popover-content .wrapper {
  display: flex;
}
.report-header-popover-content .wrapper .column {
  min-width: 300px;
}
.report-header-popover-content .wrapper .column:last-child {
  border-left: 1px solid #D8D8D8;
  padding-left: 50px;
}
.report-header-popover-content .wrapper .column .button-actions {
  display: flex;
  flex-direction: column;
}
.report-header-popover-content .wrapper .column .button-actions .action {
  background: transparent;
  border: 1px solid #21416F;
  border-radius: 24px;
  color: #21416F;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  margin: 2em auto 0;
  outline: none;
  padding: 10px 30px;
  transition: 0.3s all;
}
.report-header-popover-content .wrapper .column .button-actions .action:hover {
  background: #21416F;
  color: #fff;
}
.report-header-popover-content .wrapper .column .button-actions .apply {
  background: #21416F;
  color: #fff;
}
.report-header-popover-content .wrapper .column .button-actions .apply:disabled {
  background: #A0A7B7;
  cursor: not-allowed;
}
.report-header-popover-content .wrapper .column .button-actions .apply:hover {
  opacity: 0.5;
}
.report-header-popover-content .wrapper .column .checkboxes {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 1em;
  width: 100%;
}
.report-header-popover-content .wrapper .column .checkboxes .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.report-header-popover-content .wrapper .column .checkboxes .checkbox {
  align-items: center;
  border-bottom: 1px solid #D8D8D8;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  padding-bottom: 18px;
}
.report-header-popover-content .wrapper .column .checkboxes .checkbox:last-child {
  border-bottom: none;
}
.report-header-popover-content .wrapper .column .checkboxes .checkbox label {
  display: flex;
  padding: 0;
}
.report-header-popover-content .wrapper .column .checkboxes .checkbox .actions {
  align-items: center;
  display: flex;
}
.report-header-popover-content .wrapper .column .checkboxes .checkbox .actions .delete {
  height: 14px;
  margin-left: 10px;
  position: relative;
  transition: 0.4s all;
  width: 14px;
}
.report-header-popover-content .wrapper .column .checkboxes .checkbox .actions .delete:after {
  background: #C82922;
  border: 1px solid #C82922;
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  transform: rotate(45deg);
  width: 100%;
}
.report-header-popover-content .wrapper .column .checkboxes .checkbox .actions .delete:before {
  background: #C82922;
  border: 1px solid #C82922;
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 100%;
}
.report-header-popover-content .wrapper .column .checkboxes .checkbox .actions .delete:hover {
  opacity: 0.4;
}
.report-header-popover-content .wrapper .column .list {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  min-height: 200px;
  overflow-y: auto;
  width: 100%;
}
.report-header-popover-content .wrapper .column .list .list-item {
  align-items: center;
  border-bottom: 1px solid #DDD;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0 0.25rem 0.5rem;
  width: 100%;
}
.report-header-popover-content .wrapper .column .list .list-item:hover {
  background-color: #DDD;
  cursor: pointer;
}
.report-header-popover-content .wrapper .column .list .list-item:last-child {
  border-bottom: none;
}
.report-header-popover-content .wrapper .column .list .list-item .list-item-title {
  margin: 0;
}
.report-header-popover-content .wrapper .column .subtitle {
  color: #000;
  font-size: 12px;
  font-style: italic;
  line-height: 18px;
  margin-bottom: 14px;
}
.report-header-popover-content .wrapper .column .title {
  color: #000;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 20px;
}
.report-header-popover-content .wrapper .single {
  border-left: none !important;
  padding: 0 !important;
}