.item-wrapper {
  margin-right: 48px;
}
.item-wrapper:last-child {
  margin-right: 0;
}
.item-wrapper .item-label {
  color: #7A8295;
  font-size: 14px;
  font-style: italic;
  line-height: 23px;
  margin: 0;
}
.item-wrapper .ant-select .ant-select-selector {
  height: 100%;
  min-width: 200px;
  padding-left: 0;
}
.item-wrapper .ant-select .ant-select-selection-item {
  color: #134176;
  font-size: 18px;
  font-weight: normal;
  line-height: 23px;
}
.item-wrapper .ant-select .ant-select-arrow {
  color: #21416F;
  font-size: 12px;
  top: 50%;
}