.item-wrapper {
  margin-right: 48px;

  &:last-child {
    margin-right: 0;
  }

  .item-label {
    color: #7A8295;
    font-size: 14px;
    font-style: italic;
    line-height: 23px;
    margin: 0;
  }

  .ant-select {
    .ant-select-selector {
      height: 100%;
      min-width: 200px;
      padding-left: 0;
    }

    .ant-select-selection-item {
      color: #134176;
      font-size: 18px;
      font-weight: normal;
      line-height: 23px;
    }

    .ant-select-arrow {
      color: #21416F;
      font-size: 12px;
      top: 50%;
    }
  } 
}